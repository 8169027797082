import React from "react";

function JobCard({ title, location, jobId, link, company }) {
  return (
    <div style={styles.card}>
      <h3>
        {title} @ <span style={styles.company}>{company}</span> {/* Updated title format with span */}
      </h3>
      <p><strong>Location:</strong> {location}</p>
      <p><strong>Job ID:</strong> {jobId}</p>
      <a href={link} target="_blank" rel="noopener noreferrer" style={styles.applyButton}>
        Apply
      </a>
    </div>
  );
}

const styles = {
  card: {
    border: "1px solid #ddd",
    padding: "16px",
    margin: "8px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  company: {
    color: "#cc00ff", // Set company color to purple
  },
  applyButton: {
    display: "inline-block",
    padding: "8px 16px",
    color: "#fff",
    backgroundColor: "black",
    textDecoration: "none",
    borderRadius: "4px",
  }
};

export default JobCard;
