// src/components/Navbar.js
import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/27andyp.png";

function Navbar() {
  return (
    <nav style={styles.navbar}>
      <div style={styles.logo}>
        <Link to="/" style={styles.link}>
          <img 
            src={logo}
            alt="Logo" 
            style={styles.logoImage} 
          />
        </Link>
      </div>
      <div style={styles.rightLinks}>
        <Link to="/about" style={styles.link}>About Us</Link>
      </div>
    </nav>
  );
}

const styles = {
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    backgroundColor: "black",
    color: "#fff",
  },
  logo: {
    fontSize: "24px",
  },
  logoImage: {
    height: "40px", // Adjust as needed
    width: "auto",
  },
  rightLinks: {
    marginLeft: "auto",
  },
  link: {
    color: "#fff",
    textDecoration: "none",
    padding: "0 15px",
    fontSize: "18px",
  },
};

export default Navbar;
